<template>
  <div class="menu">
    <div class="menu__bar">
      <router-link to="/" class="menu__logo">
        <img src="@/assets/images/quendrox-logga.png" alt="Quendrox logotype" />
      </router-link>
      <MenuIcon
        v-bind:is-open.sync="this.menuIsOpen"
        v-on:changeMenuIconState="handleChangeMenuIconState($event)"
      />
    </div>
    <div
      id="expandableMenuOptions"
      class="menu__options"
      @click="toggleMenu('expandableMenuOptions', false)"
    >
      <router-link
        v-for="menuOption in menuOptions"
        v-bind:key="menuOption"
        :to="convertMenuOptionToRoute(menuOption)"
        class="menu__option"
        >{{ menuOption }}</router-link
      >
    </div>
  </div>
</template>

<script>
import MenuIcon from "@/components/menu/MenuIcon.vue";

export default {
  name: "MenuMobile",
  components: {
    MenuIcon
  },
  props: {
    menuOptions: Array
  },
  data() {
    return {
      menuIsOpen: false
    };
  },
  methods: {
    convertMenuOptionToRoute: function(menuOption) {
      return menuOption.toLowerCase().replace(" ", "-");
    },
    handleChangeMenuIconState: function(event) {
      this.toggleMenu("expandableMenuOptions", event);
    },
    toggleMenu(menuId, open) {
      this.setMenuIsOpen(open);
      this.toggleExpandableElement(menuId);
    },
    setMenuIsOpen: function(value) {
      this.menuIsOpen = value;
    },
    toggleExpandableElement: function(expandableElementId) {
      var expandableElement = document.getElementById(expandableElementId);

      if (expandableElement.style.maxHeight) {
        expandableElement.style.maxHeight = null;
      } else {
        expandableElement.style.maxHeight =
          expandableElement.scrollHeight + "px";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.menu {
  &__logo img {
    max-width: 100%;
  }

  &__options {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
  }

  &__option {
    padding: 5px;
    border-top: 1px solid var(--main-brand-color);
    text-decoration: none;
    color: var(--main-brand-color);
    font-weight: bold;

    &:hover {
      background-color: var(--light-accent);
    }
  }

  &__bar {
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
