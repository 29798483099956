<template>
  <div id="app" class="app">
    <header>
      <Menu />
      <hr />
    </header>

    <main>
      <router-view />
    </main>

    <footer>
      <hr />
      <FooterInformation />
    </footer>
  </div>
</template>

<script>
import Menu from "@/components/menu/Menu.vue";
import FooterInformation from "@/components/footer/FooterInformation.vue";

export default {
  name: "App",
  components: {
    Menu,
    FooterInformation
  }
};
</script>

<style lang="scss">
.app {
  width: 100%;
  max-width: var(--content-width);
  height: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
}

header {
  width: 100%;
  max-width: var(--content-width);
  height: unset;
  position: fixed;
  background-color: var(--light-shades);
  z-index: 2;

  @media (min-width: 600px) {
    position: initial;
    height: var(--header-desktop-height);
    background-color: transparent;
  }
}

main {
  padding-top: 5px;
  flex-grow: 1;

  @media (max-width: 599px) {
    margin-top: var(--header-mobile-height);
  }
}

footer {
  width: 100%;
  color: var(--light-accent);
  font-size: 0.9em;
  text-align: center;
}

header,
footer {
  hr {
    margin: auto;
  }
}

.center {
  display: block;
  margin: auto;
  max-width: 90%;
}
</style>
