<template>
  <div id="footerInformation" class="footerInformation">
    <p>Copyright © All Rights Reserved</p>
    <p class="con">Contact us at: info&amp;a=quendrox&amp;d=com</p>
  </div>
</template>

<script>
export default {
  name: "FooterInformation",
  methods: {
    rev() {
      let elements = document.getElementsByClassName("con");
      elements[0].innerHTML = elements[0].innerHTML
        .replace("&amp;a=", "@")
        .replace("&amp;d=", ".");
    }
  },
  mounted() {
    this.rev();
  }
};
</script>

<style scoped>
.footerInformation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
