<template>
  <nav>
    <MenuDesktop class="menuDesktop" v-bind:menuOptions="menuOptions" />
    <MenuMobile class="menuMobile" v-bind:menuOptions="menuOptions" />
  </nav>
</template>

<script>
import MenuDesktop from "@/components/menu/MenuDesktop.vue";
import MenuMobile from "@/components/menu/MenuMobile.vue";

export default {
  name: "Menu",
  components: {
    MenuDesktop,
    MenuMobile
  },
  data() {
    return {
      menuOptions: [
        "Home",
        "About",
        "Contact",
        "Quendrox consulting",
        "Quendrox HA",
        "Quendrox services"
      ]
    };
  }
};
</script>

<style scoped lang="scss">
@media (max-width: 599px) {
  .menuDesktop {
    display: none;
  }
}

@media (min-width: 600px) {
  .menuMobile {
    display: none;
  }
}
</style>
