<template>
  <div id="moz-reflect">
    <img :src="getImagePath()" :alt="altText" />
    <br />
    <img :src="getImagePath()" :alt="altText" />
  </div>
</template>

<script>
export default {
  name: "ImageWithFadingRefelction",
  props: ["assetPath", "altText"],
  methods: {
    getImagePath() {
      return require(`@/assets/${this.assetPath}`);
    }
  }
};
</script>

<style scoped lang="scss">
#moz-reflect {
  position: relative;
  img:nth-of-type(2) {
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    transform: scaleY(-1);

    -webkit-mask-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }
}
</style>
