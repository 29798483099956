<template>
  <div class="menu">
    <router-link to="/" class="menu__logo">
      <ImageWithFadingRefelction assetPath="images/quendrox-logga.png" />
    </router-link>

    <div class="menu__options">
      <router-link
        v-for="menuOption in menuOptions"
        v-bind:key="menuOption"
        :to="convertMenuOptionToRoute(menuOption)"
        class="menu__option"
        >{{ menuOption }}</router-link
      >
    </div>
  </div>
</template>

<script>
import ImageWithFadingRefelction from "@/components/images/ImageWithFadingRefelction.vue";

export default {
  name: "MenuDesktop",
  components: {
    ImageWithFadingRefelction
  },
  props: {
    menuOptions: Array
  },
  methods: {
    convertMenuOptionToRoute: function(menuOption) {
      return menuOption.toLowerCase().replace(" ", "-");
    }
  }
};
</script>

<style scoped lang="scss">
.menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 5px;
  align-items: center;

  &__logo {
    max-height: 100%;
    margin-left: 5px;
  }

  &__options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__option {
    background-color: var(--main-brand-color);
    color: var(--light-shades);

    width: 80px;
    height: 40px;
    margin: 5px;
    border-radius: 5px;

    display: flex;
    flex-basis: 30%;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: var(--dark-accent);
      transition: 0.2s;
    }
  }
}
</style>
