import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/home",
    redirect: "/"
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/quendrox-consulting",
    name: "QuendroxConsulting",
    component: () => import("../views/QuendroxConsulting.vue")
  },
  {
    path: "/quendrox-ha",
    name: "QuendroxHa",
    component: () => import("../views/QuendroxHa.vue")
  },
  {
    path: "/quendrox-services",
    name: "QuendroxServices",
    component: () => import("../views/QuendroxServices.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
